.app {
  font-family: "Roboto";
  height: 50px;
  padding: 8px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.dropdown-container {
  width: 250px;
  position: relative;
}

.dropdown-container input {
  width: 100%;
  padding: 0;
  border-width: 1px;
  height: 36px;
  padding-left: 10px;
  box-sizing: border-box;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-arrow-container {
  position: absolute;
  right: 10px;
  top: 20%;
  transform: rotateY(-50%);
  margin-top: -2px;
  cursor: pointer;
}

.input-arrow {
  border: solid #272727;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(45deg);
  -webkit-transform: rotate(45deg);
}

.option {
  height: 36px;
  border-bottom: 1px solid #272727;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  background-color: #fff;
}

.option:hover {
  background-color: royalblue;
}

.dropdown {
  border: 1px solid #272727;
  border-bottom: none;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  display: none;
}

.dropdown.visible {
  display: block;
}

.input-clear-container {
  position: absolute;
  right: 30px;
  top: 20%;
  transform: rotateY(-50%);
  cursor: pointer;
}

header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 2rem;
  background-color: #fffcf7;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

nav a {
  margin: 0 2rem;
  color: #fff;
  text-decoration: none;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 1084px){
  header .nav-btn{
    visibility: visible;
    opacity: 1;
  }
  header nav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #892a10;
    transition: 1s;
    transform: rotateY(-100vh);
    z-index: 99;
  }
  header .responsive_nav{
    transform: none;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}
