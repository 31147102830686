* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

/* body {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  background: #ede7f6;
} */

footer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 100px;
  background: #111;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer .container {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  flex-direction: row;
}

footer .container .sec {
  margin-right: 30px;
}

footer .container .sec.aboutus {
  width: 40%;
}

footer .container h2 {
  position: relative;
  color: #fff;
  font-weight: 500;
  margin-bottom: 16px;
}

footer .container h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background: #de6b1a;
}

footer p {
  color: #999;
}

.sci {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.sci li {
  list-style: none;
}

.sci li a {
  /* display: inline-block; */
  width: 40px;
  height: 40px;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-decoration: none;
  border-radius: 50%;
}

.sci li a:hover {
  background: #892a10;
}

.sci li a .fa-at {
  color: #fff;
  font-size: 20px;
}

.sci li a .fa-at:hover {
  color: #1cb803;
  font-size: 20px;
}

.sci li a .fa-facebook {
  color: #fff;
  font-size: 20px;
}

.sci li a .fa-facebook:hover {
  color: #1cb803;
  font-size: 20px;
}

.quickLinks {
  position: relative;
  width: 25%;
}

.quickLinks ul li a {
  color: #999;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}

.quickLinks ul li a:hover {
  color: #fff;
}

.quickLinks ul {
  list-style: none;
  padding-left: 0px !important;
}

.offer {
  position: relative;
  width: 25%;
}

.offer ul li a {
  color: #999;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}

.offer ul li a:hover {
  color: #fff;
}

.offer ul {
  list-style: none;
  padding-left: 0px !important;
}

.contact {
  width: calc(35% -60px);
  margin-right: 0 !important;
}

.contact ul {
  list-style: none;
  padding-left: 0px !important;
}

.contact .info li {
  display: flex;
  margin-bottom: 16px;
}

.contact .info li span:nth-child(1) {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.contact .info li span {
  color: #999;
}

.contact .info li a {
  color: #999;
  text-decoration: none;
}

.contact .info li a:hover {
  color: #fff;
}

.copyrightText {
  width: 100%;
  background: #181818;
  padding: 8px 100px;
  text-align: center;
  color: #999;
}

.copyrightText a {
  color: #999;
  text-decoration: none;
  color: #1cb803;
}

.copyrightText img {
  width: 120px;
  height: 35px;
  margin-right: 8px;
  cursor: pointer;
}

.copyrightText p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.author {
  margin-left: 6px;
}

@media (min-width) {
  footer {
    padding: 0;
  }
  footer .container {
    flex-direction: column;
  }
  footer .container .sec {
    margin-right: 0 !important;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 996px) {
  footer {
    padding: 8px;
  }
  footer .container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 910px) {
  footer .container .sec.aboutus {
    width: 40%;
  }
  footer .container .sec.quickLinks {
    width: 40%;
  }
  footer .container .sec.offer {
    width: 40%;
  }
  footer .container .sec.contact {
    width: 40%;
  }
}

@media only screen and (max-width: 642px) {
  .offer {
    width: 41%;
  }
}

@media only screen and (max-width: 600px) {
  .copyrightText p {
    flex-wrap: wrap;
    flex-direction: column;
  }
  footer .container .sec.aboutus {
    width: 100%;
  }
  footer .container .sec.quickLinks {
    width: 100%;
  }
  footer .container .sec.offer {
    width: 100%;
  }
  footer .container .sec.contact {
    width: 100%;
  }
  .sci {
    justify-content: center;
  }
}

@media only screen and (max-width: 594px) {
  .copyrightText p {
    flex-wrap: wrap;
  }
  .copyrightText img {
    width: 100%;
    height: unset;
  }
}

@media only screen and (max-width: 594px) {
  .footText {
    width: 220px;
  }
}

@media only screen and (max-width: 540px) {
  .footText {
    width: 500px;
  }
}

@media (max-width: 414px) {
  .footText {
    max-width: 100% !important;
  }
  footer .container .sec {
    margin-right: unset;
  }
}
