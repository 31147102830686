body {
  margin: 0;
  font-family: "Roboto";
  /* font-family: "Josefin Sans", sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f1f1f1;
}

.vente-flash {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #355421;
}

.nopub {
  display: none
}

.txt {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  animation: Scroll 12s linear infinite;
}

@keyframes Scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.section-scroll {
  overflow-y: scroll;
  height: calc(100vh - 174px);
  padding: 16px;
}

.section-scroll-flashsale {
  overflow-x: scroll;
  height: calc(100vh - 200px);
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}

.row,
.col-6,
.col-4 {
  margin: 0;
  padding: 0;
}

.Announcement {
  background-color: #1cb803;
  padding: 10px 0px 6px 0px;
}

.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}

.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  background: burlywood;
}

.head-row {
  justify-content: space-between;
}

.pc-header {
  padding: 4px;
}

.logo {
  width: 34%;
}

.box-shop {
  display: flex;
  align-items: center;
}

.head-contact-root {
  background: #ffeed9;
  padding: 12px;
  border-left: 1px solid burlywood;
  border-radius: 34px 0 0 34px;
  margin-left: 12px;
  background: #f3fbf7;
}

.head-contact {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.help-contact {
  font-size: 11px;
  font-family: "Roboto";
  font-weight: 600;
  color: #507f32;
  cursor: help;
  text-transform: uppercase;
}

.departement {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 600;
  color: #892a10;
  cursor: help;
}

.contact-departement {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 600;
  margin-left: 8px;
  color: #892a10;
  cursor: help;
}

.navbar-brand img {
  width: 85%;
  margin: auto;
}

.input-group {
  height: 50px;
  width: 90%;
}

.email {
  /* margin-left: -16px; */
}

.nom {
  /* margin-left: -16px; */
}

.search-button {
  width: 20%;
  background-color: #892a10;
  color: #ffffff;
  border: 0;
  text-transform: capitalize;
  font-size: 12px;
  border-radius: 8px;
  font-size: 18px;
}

.register {
  margin-right: 8px;
}

.Login-Register {
  width: 26% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.Login-Register a {
  text-transform: initial;
  font-size: 16px;
  margin-right: 12px;
  position: relative;
  color: #fff;
}

.Login-Register a:hover {
  color: #1cb803;
  transition: 0.5s;
}

.Login-Register a:nth-last-child(1) {
  margin-right: 0rem;
}

.badge {
  background-color: #892a10;
  position: absolute;
  top: -20px;
  border-radius: 100px;
  padding: 5px 7px;
  font-size: 12px;
}

.name-button {
  padding: 5px 15px;
  border: 1px solid #e4e4e4;
  background-color: white;
  border-radius: 8px;
  margin: 0 15px;
}

.dropdown-menu {
  background-color: #892a10 !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.dropdown-menu a {
  padding: 10px 20px !important;
  color: #ffffff !important;
  margin: 0 !important;
}

.dropdown-menu a:hover {
  background-color: #1cb803 !important;
  color: #892a10 !important;
}

.mobile-header {
  display: none;
}

.fa-shopping-bag:before {
  color: #1cb803;
}

/* SHOP */
.section {
  width: 100%;
  /* padding-bottom: 6%;
  padding-top: 2%; */
}

.sidebar_item {
  margin-bottom: 35px;
}

.sidebar_item h4 {
  color: #1c1c1c;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}

.sidebar_item ul {
  margin: 0;
  padding: 0;
}

.sidebar_item ul li {
  list-style: none;
  display: flex;
}

.sidebar_item ul li a {
  font-size: 16px;
  color: #000000;
  line-height: 45px;
  display: block;
  font-family: "Open Sans", sans-serif;
}

.sidebar_item ul li img {
  width: 25px;
  object-fit: contain;
  margin-left: 20px;
}

.shop {
  margin-bottom: 50px;
  cursor: pointer;
}

.border-product {
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
}

#visibility {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.border-product:hover #visibility {
  opacity: 1
}

.shopBack {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #f3fbf7;
}

/* .shopBack:hover {
  transition: all 0.5s ease-in-out;
} */
.shopBack img {
  width: 100%;
  height: 250px;
  object-fit: contain;
  transition: all 1s;
}

.shopBack:hover img {
  transform: scale(1.1);
}

.shopBack img:hover {
  width: 100%;
  height: 250px;
  object-fit: contain;
  transition: all 0.5s ease-in-out;
}

.shoptext {
  padding-top: 10px;
}

.shoptext p a {
  color: #252525;
  text-decoration: none;
}

.shoptext h3 {
  color: #252525;
  margin-top: 13px;
  font-weight: bold;
  font-size: 19px;
}

.seconnect {
  display: flex;
  justify-content: center;
}

.rating {
  margin: 5px 0;
}

.rating i {
  font-size: 12px;
  color: #ebb450;
}

.rating span {
  margin-left: 10px;
}

/* END OF SHOP */

/* NEWSLATTER */
.subscribe-section {
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.226),
      rgba(0, 0, 0, 0.226)),
    url("./assets/img/sifaka_logo.png");
  /* url("https://sasshoes-com.s3.us-west-2.amazonaws.com/img/banner-uplift-21.jpg"); */
  padding: 100px 0 100px;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
}

.subscribe-head h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 13px;
  text-transform: uppercase;
}

.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}

.subscribe-head {
  text-align: center;
}

.form-section input[type="email"] {
  border: 0 none;
  border-radius: 25px;
  color: #9b9b9b;
  font-size: 16px;
  font-style: normal;
  height: 51px;
  letter-spacing: 0;
  text-align: center;
  width: 460px;
}

.form-section input[type="submit"] {
  background: #1cb803;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-left: -60px;
  padding: 0 58px;
  text-transform: uppercase;
  font-size: 12px;
}

/* CONTACT */
.contactInfo {
  padding: 70px 0;
}

.contact-Box,
.box-info,
.info-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-email {
  cursor: pointer;
}

.box-info {
  width: 80%;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0px 0px 27px 0px rgb(243, 243, 243);
}

.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #1cb803;
  border-radius: 100px;
  font-size: 20px;
  color: #de6b1a;
}

.box-info h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 4px;
}

.box-info p {
  font-size: 16px;
  margin-bottom: unset;
}

.flashsale {
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-family: "Roboto";
  font-weight: 600;
  color: #892a10;
  padding: 12px;
}

/* SINGLE PRODUCT */
.single-product {
  margin-top: 16px;
  margin-bottom: 16px;
  overflow-x: scroll;
  height: calc(100vh - 90px);
}

.single-image {
  background-color: #f3fbf7;
  margin-right: 20px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-image img {
  width: 90%;
  height: 700px;
  object-fit: contain;
}

.product-name {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 30px;
}

.display-flex {
  display: flex;
}

.product-info {
  width: 100%;
}

.product-dtl p {
  line-height: 24px;
  color: #7a7a7a;
}

.product-count {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  margin-top: 40px;
}

.product-count .flex-box {
  padding: 15px 25px;
  border-bottom: 2px solid #f3f3f3;
}

.product-count .flex-box h6 {
  font-size: 16px;
}

.product-count .flex-box span {
  font-weight: 600;
  color: #000000;
}

.product-count .flex-box select {
  background-color: #f3f3f3;
  width: 100px;
  height: 40px;
  text-align: center;
  border: 0px;
  border-radius: 5px;
}
.box-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}
.btnAccueil {
  width: 49%;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
}
.btnAccueil:hover,
.btnAccueil:focus {
  background-color: transparent;
  color: #892a10;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  height: 50px;
} 
.round-black-btn {
  border-radius: 4px;
  background: #892a10;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.5s ease-in-out 0s;
  width: 49%;
}

.round-black-btn:hover,
.round-black-btn:focus {
  background: #1cb803;
}

/* LOGIN */
.login-center {
  padding: 10px;
}

.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 8px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
}

.Login input {
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #8a8a8a;
}

.Login input:focus {
  outline: 0px;
}

.Login button,
.Login2 button {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border: 0;
  background-color: #1cb803;
  border-radius: 5px;
  text-transform: uppercase;
  color: white;
}

.Login button a,
.Login2 button a {
  text-transform: uppercase;
  color: white;
}

.Login p {
  margin-top: 30px;
}

.Login p a {
  color: #7a7a7a;
  text-decoration: none;
}

.boxRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #de6b1a;
  color: #fff;
  margin-top: 8px;
  padding: 12px;
  border-radius: 0 0 8px 8px;
}

/* PROFILE */
.author-card {
  position: relative;
}

.author-card-cover {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-image: url("https://gust.com/assets/blank_slate/Gust_Profile_CoverPhoto_Blank-21edf1e2890708d5a507204f49afc10b7dc58eb7baea100b68a1bc2c96948297.png");
}

.author-card-profile {
  padding: 20px 10px;
}

.author-card-avatar {
  padding: 0 20px;
}

.author-card-avatar img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 100px;
  margin-top: -60%;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377);
}

.wizard .nav button {
  padding: 15px 20px;
  font-size: 13px;
  background-color: #ffffff;
  color: black;
  width: 100%;
  text-align: start;
  border-radius: 0;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
}

.wizard .nav .active {
  background-color: #e5fee9;
  color: black;
}

.badge2 {
  width: 20px;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #ffffff;
}

.form {
  padding: 20px 0;
}

.form label {
  padding: 10px 0;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 13px;
}

.form input {
  padding: 20px 10px;
  font-size: 17px;
  background-color: #e5fee9;
}

.form-container button {
  padding: 15px 0;
  border: 0px;
  /* margin-top: 20px; */
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
  width: 98%;
  margin: auto;
  border-radius: 8px;
}

/* orders */
.link {
  color: blue;
  border-bottom: 1px solid blue;
  padding-bottom: 5px;
  font-size: 15px;
}

.link:hover {
  color: blue;
}

.table-responsive {
  width: 100%;
}

/* CART */
.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
  position: relative;
}

.remove-button {
  background-color: #892a10;
  width: 25px !important;
  height: 25px;
  border-radius: 100px;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}

.cart-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.cart-text a h4,
.cart-price h4 {
  font-size: 17px;
  font-weight: bold;
}

.cart-text p {
  margin-top: 10px;
  font-size: 15px;
  color: #7a7a7a;
}

.cart-qty select {
  width: 100%;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}

.cart-qty h6,
.cart-price h6 {
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  text-transform: uppercase;
}

.total {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: end;
  padding: 0 30px;
}

.total .sub {
  color: #8c8c8c;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 20px;
}

.total .total-price {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.cart-buttons {
  margin: 90px 0;
}

.cart-buttons a button,
.cart-buttons div button {
  width: 80%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
}

.cart-buttons a:nth-child(1) button {
  background-color: #000000;
}

/* FOOTER */
.footer {
  background-color: #ececec;
}

.card-name {
  width: 4%;
  margin: 10px;
}

.card-name img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

/* Payment */
.payment-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paiementCash {
  text-decoration: none;
  color: #892a10;
}

.paiementCash:hover {
  color: #892a10;
}

.radio-container {
  padding: 10px 0;
  text-align: start;
}

.radio-container input {
  font-size: 17px;
}

.radio-container label {
  padding: 0 20px;
  font-size: 17px;
}

.order-detail p {
  margin-top: 2px;
}

.order-detail {
  padding: 12px 0;
  background-color: #e5fee9;
}

.order-detail h5 {
  margin-bottom: 6px;
}

.order-box {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}

.order-product h4 {
  font-size: 13px;
  margin-bottom: 10px;
}

.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.table-bordered {
  background-color: #f3f3f3;
}

.subtotal-order button {
  width: 100%;
  padding: 15px 0;
  border: 0px;
  text-transform: capitalize;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
  border-radius: 8px;
}

/* Pagination */
.pagination .page-link {
  padding: 10px 30px;
  color: #892a10 !important;
}

.page-item.active .page-link {
  background-color: #892a10 !important;
  border: 1px solid #892a10 !important;
  color: #1cb803 !important;
}

.container {
  max-width: 100% !important;
}

.note {
  text-decoration: none;
  color: #892a10;
}

.note:hover {
  color: #892a10;
}

.select {
  cursor: pointer;
}

.detailProduct {
  width: 100%;
}

.boxNote {
  width: 100% !important;
  margin-top: 8px;
  padding: 4px;
}

.comment {
  width: 100% !important;
}

.productName {
  text-decoration: none;
  color: #212121;
}

.productName:hover {
  color: #212121;
}

.cart-row {
  max-width: 98%;
  margin: auto !important;
  margin-bottom: 16px !important;
}

.cart-buttons-cart {
  border-radius: 8px;
}

.order-details {
  padding: 8px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7))
  }
}

.slider{
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}
.slider .slid-track{
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide{
  height: 100px;
  width: 250px;
}

@media only screen and (max-width: 770px) {
  .header {
    height: 114px;
  }

  .subscribe-section {
    background-size: contain;
  }

  .alert {
    font-size: 11px;
    font-family: Roboto
  }

  .px-3 h1 {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400
  }

  .total {
    margin-top: unset
  }
}