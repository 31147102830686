/* purgecss start ignore */

body {
  margin: 0;
  font-family: "Roboto";
  /* font-family: "Josefin Sans", sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f1f1f1;
}

.vente-flash {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #355421;
}

.nopub {
  display: none
}

.txt {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  -webkit-animation: Scroll 12s linear infinite;
  animation: Scroll 12s linear infinite;
}

@-webkit-keyframes Scroll {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes Scroll {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.section-scroll {
  overflow-y: scroll;
  height: calc(100vh - 174px);
  padding: 16px;
}

.section-scroll-flashsale {
  overflow-x: scroll;
  height: calc(100vh - 200px);
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}

.row,
.col-6,
.col-4 {
  margin: 0;
  padding: 0;
}

.Announcement {
  background-color: #1cb803;
  padding: 10px 0px 6px 0px;
}

.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}

.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}

/* Header */

.header {
  display: flex;
  align-items: center;
  background: burlywood;
}

.head-row {
  justify-content: space-between;
}

.pc-header {
  padding: 4px;
}

.logo {
  width: 34%;
}

.box-shop {
  display: flex;
  align-items: center;
}

.head-contact-root {
  background: #ffeed9;
  padding: 12px;
  border-left: 1px solid burlywood;
  border-radius: 34px 0 0 34px;
  margin-left: 12px;
  background: #f3fbf7;
}

.head-contact {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.help-contact {
  font-size: 11px;
  font-family: "Roboto";
  font-weight: 600;
  color: #507f32;
  cursor: help;
  text-transform: uppercase;
}

.departement {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 600;
  color: #892a10;
  cursor: help;
}

.contact-departement {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 600;
  margin-left: 8px;
  color: #892a10;
  cursor: help;
}

.navbar-brand img {
  width: 85%;
  margin: auto;
}

.input-group {
  height: 50px;
  width: 90%;
}

.email {
  /* margin-left: -16px; */
}

.nom {
  /* margin-left: -16px; */
}

.search-button {
  width: 20%;
  background-color: #892a10;
  color: #ffffff;
  border: 0;
  text-transform: capitalize;
  font-size: 12px;
  border-radius: 8px;
  font-size: 18px;
}

.register {
  margin-right: 8px;
}

.Login-Register {
  width: 26% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.Login-Register a {
  text-transform: initial;
  font-size: 16px;
  margin-right: 12px;
  position: relative;
  color: #fff;
}

.Login-Register a:hover {
  color: #1cb803;
  transition: 0.5s;
}

.Login-Register a:nth-last-child(1) {
  margin-right: 0rem;
}

.badge {
  background-color: #892a10;
  position: absolute;
  top: -20px;
  border-radius: 100px;
  padding: 5px 7px;
  font-size: 12px;
}

.name-button {
  padding: 5px 15px;
  border: 1px solid #e4e4e4;
  background-color: white;
  border-radius: 8px;
  margin: 0 15px;
}

.dropdown-menu {
  background-color: #892a10 !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.dropdown-menu a {
  padding: 10px 20px !important;
  color: #ffffff !important;
  margin: 0 !important;
}

.dropdown-menu a:hover {
  background-color: #1cb803 !important;
  color: #892a10 !important;
}

.mobile-header {
  display: none;
}

.fa-shopping-bag:before {
  color: #1cb803;
}

/* SHOP */

.section {
  width: 100%;
  /* padding-bottom: 6%;
  padding-top: 2%; */
}

.sidebar_item {
  margin-bottom: 35px;
}

.sidebar_item h4 {
  color: #1c1c1c;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}

.sidebar_item ul {
  margin: 0;
  padding: 0;
}

.sidebar_item ul li {
  list-style: none;
  display: flex;
}

.sidebar_item ul li a {
  font-size: 16px;
  color: #000000;
  line-height: 45px;
  display: block;
  font-family: "Open Sans", sans-serif;
}

.sidebar_item ul li img {
  width: 25px;
  object-fit: contain;
  margin-left: 20px;
}

.shop {
  margin-bottom: 50px;
  cursor: pointer;
}

.border-product {
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
}

#visibility {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.border-product:hover #visibility {
  opacity: 1
}

.shopBack {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #f3fbf7;
}

/* .shopBack:hover {
  transition: all 0.5s ease-in-out;
} */

.shopBack img {
  width: 100%;
  height: 250px;
  object-fit: contain;
  transition: all 1s;
}

.shopBack:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.shopBack img:hover {
  width: 100%;
  height: 250px;
  object-fit: contain;
  transition: all 0.5s ease-in-out;
}

.shoptext {
  padding-top: 10px;
}

.shoptext p a {
  color: #252525;
  text-decoration: none;
}

.shoptext h3 {
  color: #252525;
  margin-top: 13px;
  font-weight: bold;
  font-size: 19px;
}

.seconnect {
  display: flex;
  justify-content: center;
}

.rating {
  margin: 5px 0;
}

.rating i {
  font-size: 12px;
  color: #ebb450;
}

.rating span {
  margin-left: 10px;
}

/* END OF SHOP */

/* NEWSLATTER */

.subscribe-section {
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.226),
      rgba(0, 0, 0, 0.226)),
    url(/static/media/sifaka_logo.6240731f.png);
  /* url("https://sasshoes-com.s3.us-west-2.amazonaws.com/img/banner-uplift-21.jpg"); */
  padding: 100px 0 100px;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
}

.subscribe-head h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 13px;
  text-transform: uppercase;
}

.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}

.subscribe-head {
  text-align: center;
}

.form-section input[type="email"] {
  border: 0 none;
  border-radius: 25px;
  color: #9b9b9b;
  font-size: 16px;
  font-style: normal;
  height: 51px;
  letter-spacing: 0;
  text-align: center;
  width: 460px;
}

.form-section input[type="submit"] {
  background: #1cb803;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-left: -60px;
  padding: 0 58px;
  text-transform: uppercase;
  font-size: 12px;
}

/* CONTACT */

.contactInfo {
  padding: 70px 0;
}

.contact-Box,
.box-info,
.info-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-email {
  cursor: pointer;
}

.box-info {
  width: 80%;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0px 0px 27px 0px rgb(243, 243, 243);
}

.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #1cb803;
  border-radius: 100px;
  font-size: 20px;
  color: #de6b1a;
}

.box-info h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 4px;
}

.box-info p {
  font-size: 16px;
  margin-bottom: unset;
}

.flashsale {
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-family: "Roboto";
  font-weight: 600;
  color: #892a10;
  padding: 12px;
}

/* SINGLE PRODUCT */

.single-product {
  margin-top: 16px;
  margin-bottom: 16px;
  overflow-x: scroll;
  height: calc(100vh - 90px);
}

.single-image {
  background-color: #f3fbf7;
  margin-right: 20px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-image img {
  width: 90%;
  height: 700px;
  object-fit: contain;
}

.product-name {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 30px;
}

.display-flex {
  display: flex;
}

.product-info {
  width: 100%;
}

.product-dtl p {
  line-height: 24px;
  color: #7a7a7a;
}

.product-count {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  margin-top: 40px;
}

.product-count .flex-box {
  padding: 15px 25px;
  border-bottom: 2px solid #f3f3f3;
}

.product-count .flex-box h6 {
  font-size: 16px;
}

.product-count .flex-box span {
  font-weight: 600;
  color: #000000;
}

.product-count .flex-box select {
  background-color: #f3f3f3;
  width: 100px;
  height: 40px;
  text-align: center;
  border: 0px;
  border-radius: 5px;
}

.box-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}

.btnAccueil {
  width: 49%;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
}

.btnAccueil:hover,
.btnAccueil:focus {
  background-color: transparent;
  color: #892a10;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  height: 50px;
}

.round-black-btn {
  border-radius: 4px;
  background: #892a10;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.5s ease-in-out 0s;
  width: 49%;
}

.round-black-btn:hover,
.round-black-btn:focus {
  background: #1cb803;
}

/* LOGIN */

.login-center {
  padding: 10px;
}

.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 8px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
}

.Login input {
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #8a8a8a;
}

.Login input:focus {
  outline: 0px;
}

.Login button,
.Login2 button {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border: 0;
  background-color: #1cb803;
  border-radius: 5px;
  text-transform: uppercase;
  color: white;
}

.Login button a,
.Login2 button a {
  text-transform: uppercase;
  color: white;
}

.Login p {
  margin-top: 30px;
}

.Login p a {
  color: #7a7a7a;
  text-decoration: none;
}

.boxRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #de6b1a;
  color: #fff;
  margin-top: 8px;
  padding: 12px;
  border-radius: 0 0 8px 8px;
}

/* PROFILE */

.author-card {
  position: relative;
}

.author-card-cover {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-image: url("https://gust.com/assets/blank_slate/Gust_Profile_CoverPhoto_Blank-21edf1e2890708d5a507204f49afc10b7dc58eb7baea100b68a1bc2c96948297.png");
}

.author-card-profile {
  padding: 20px 10px;
}

.author-card-avatar {
  padding: 0 20px;
}

.author-card-avatar img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 100px;
  margin-top: -60%;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377);
}

.wizard .nav button {
  padding: 15px 20px;
  font-size: 13px;
  background-color: #ffffff;
  color: black;
  width: 100%;
  text-align: start;
  border-radius: 0;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
}

.wizard .nav .active {
  background-color: #e5fee9;
  color: black;
}

.badge2 {
  width: 20px;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #ffffff;
}

.form {
  padding: 20px 0;
}

.form label {
  padding: 10px 0;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 13px;
}

.form input {
  padding: 20px 10px;
  font-size: 17px;
  background-color: #e5fee9;
}

.form-container button {
  padding: 15px 0;
  border: 0px;
  /* margin-top: 20px; */
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
  width: 98%;
  margin: auto;
  border-radius: 8px;
}

/* orders */

.link {
  color: blue;
  border-bottom: 1px solid blue;
  padding-bottom: 5px;
  font-size: 15px;
}

.link:hover {
  color: blue;
}

.table-responsive {
  width: 100%;
}

/* CART */

.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
  position: relative;
}

.remove-button {
  background-color: #892a10;
  width: 25px !important;
  height: 25px;
  border-radius: 100px;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}

.cart-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.cart-text a h4,
.cart-price h4 {
  font-size: 17px;
  font-weight: bold;
}

.cart-text p {
  margin-top: 10px;
  font-size: 15px;
  color: #7a7a7a;
}

.cart-qty select {
  width: 100%;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}

.cart-qty h6,
.cart-price h6 {
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  text-transform: uppercase;
}

.total {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: end;
  padding: 0 30px;
}

.total .sub {
  color: #8c8c8c;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 20px;
}

.total .total-price {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.cart-buttons {
  margin: 90px 0;
}

.cart-buttons a button,
.cart-buttons div button {
  width: 80%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
}

.cart-buttons a:nth-child(1) button {
  background-color: #000000;
}

/* FOOTER */

.footer {
  background-color: #ececec;
}

.card-name {
  width: 4%;
  margin: 10px;
}

.card-name img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

/* Payment */

.payment-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paiementCash {
  text-decoration: none;
  color: #892a10;
}

.paiementCash:hover {
  color: #892a10;
}

.radio-container {
  padding: 10px 0;
  text-align: start;
}

.radio-container input {
  font-size: 17px;
}

.radio-container label {
  padding: 0 20px;
  font-size: 17px;
}

.order-detail p {
  margin-top: 2px;
}

.order-detail {
  padding: 12px 0;
  background-color: #e5fee9;
}

.order-detail h5 {
  margin-bottom: 6px;
}

.order-box {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}

.order-product h4 {
  font-size: 13px;
  margin-bottom: 10px;
}

.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.table-bordered {
  background-color: #f3f3f3;
}

.subtotal-order button {
  width: 100%;
  padding: 15px 0;
  border: 0px;
  text-transform: capitalize;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
  border-radius: 8px;
}

/* Pagination */

.pagination .page-link {
  padding: 10px 30px;
  color: #892a10 !important;
}

.page-item.active .page-link {
  background-color: #892a10 !important;
  border: 1px solid #892a10 !important;
  color: #1cb803 !important;
}

.container {
  max-width: 100% !important;
}

.note {
  text-decoration: none;
  color: #892a10;
}

.note:hover {
  color: #892a10;
}

.select {
  cursor: pointer;
}

.detailProduct {
  width: 100%;
}

.boxNote {
  width: 100% !important;
  margin-top: 8px;
  padding: 4px;
}

.comment {
  width: 100% !important;
}

.productName {
  text-decoration: none;
  color: #212121;
}

.productName:hover {
  color: #212121;
}

.cart-row {
  max-width: 98%;
  margin: auto !important;
  margin-bottom: 16px !important;
}

.cart-buttons-cart {
  border-radius: 8px;
}

.order-details {
  padding: 8px;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7))
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7))
  }
}

.slider{
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.slider .slid-track{
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide{
  height: 100px;
  width: 250px;
}

@media only screen and (max-width: 770px) {
  .header {
    height: 114px;
  }

  .subscribe-section {
    background-size: contain;
  }

  .alert {
    font-size: 11px;
    font-family: Roboto
  }

  .px-3 h1 {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400
  }

  .total {
    margin-top: unset
  }
}

/* purgecss end ignore */
*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

.container {
  width: 100%
}

@media (min-width: 988px) {
  .container {
    max-width: 988px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1308px) {
  .container {
    max-width: 1308px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.top-20 {
  top: 5rem
}

.left-3 {
  left: 0.75rem
}

.z-50 {
  z-index: 50
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-8 {
  margin-top: 2rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mb-7 {
  margin-bottom: 1.75rem
}

.ml-7 {
  margin-left: 1.75rem
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.group:hover .group-hover\:block {
  display: block
}

.h-4 {
  height: 1rem
}

.h-10 {
  height: 2.5rem
}

.h-16 {
  height: 4rem
}

.h-screen {
  height: 100vh
}

.w-4 {
  width: 1rem
}

.w-16 {
  width: 4rem
}

.w-full {
  width: 100%
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-45 {
  --tw-rotate: 45deg
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.cursor-pointer {
  cursor: pointer
}

.list-none {
  list-style-type: none
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.flex-col {
  flex-direction: column
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-10 {
  gap: 2.5rem
}

.rounded {
  border-radius: 0.25rem
}

.border-0 {
  border-width: 0px
}

.border {
  border-width: 1px
}

.border-b-2 {
  border-bottom-width: 2px
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
}

.object-cover {
  object-fit: cover
}

.p-0 {
  padding: 0px
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pb-0 {
  padding-bottom: 0px
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pb-8 {
  padding-bottom: 2rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity))
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity))
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity))
}

.opacity-40 {
  opacity: 0.4
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

@media (min-width: 988px) {
  .laptop\:mt-8 {
    margin-top: 2rem
  }

  .laptop\:mr-28 {
    margin-right: 7rem
  }

  .laptop\:grid {
    display: grid
  }

  .laptop\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}

@media (min-width: 1024px) {
  .md\:mt-8 {
    margin-top: 2rem
  }

  .md\:mr-28 {
    margin-right: 7rem
  }

  .md\:cursor-pointer {
    cursor: pointer
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1
  }
}

@media (min-width: 1308px) {
  .lg\:mt-8 {
    margin-top: 2rem
  }

  .lg\:mr-48 {
    margin-right: 12rem
  }

  .lg\:h-2\/3 {
    height: 66.666667%
  }

  .lg\:gap-6 {
    gap: 1.5rem
  }

  .lg\:p-40 {
    padding: 10rem
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1
  }
}

@media (min-width: 1536px) {
}
/* purgecss start ignore */

@media (max-width: 1624px) {
  .head-contact-root {
    margin-left: 45px;
  }

  .order-details {
    overflow-y: scroll;
    height: calc(100vh - 242px);
  }
}

@media (max-width: 1424px) {
  .logo {
    width: 45%;
  }

  .navbar-brand img {
    width: 100%;
  }

  .Login-Register {
    width: 38% !important;
  }

  .head-contact-root {
    padding: 13px;
    margin-left: 60px;
  }

  .head-contact {
    width: 308px;
  }

  .Login input {
    padding: 8px;
  }

  .section-scroll {
    height: calc(100vh - 196px);
  }

  .help-contact {
    margin-left: 8px;
  }
}

@media (max-width: 1306px) {
  .logo {
    width: 44%;
  }

  .navbar-brand img {
    width: 100%;
  }

  .Login-Register {
    width: 38% !important;
  }

  .head-contact-root {
    padding: 10px;
  }
}

@media (max-width: 1024px) {
  .row {
    justify-content: space-between;
  }

  .d-flex-search {
    width: 312px !important;
  }
}

@media (max-width: 991.98px) {
  .row {
    justify-content: space-between;
  }

  .display-none {
    display: none !important;
  }

  .search-button {
    font-size: 10px;
  }

  .Login-Register {
    width: 30% !important;
  }

  .Login-Register a {
    font-size: 10px;
  }

  .btn-group button {
    font-size: 13px;
  }

  .badge {
    top: -10px;
    padding: 5px;
    font-size: 7px;
  }

  .input-group {
    height: 30px;
  }

  .single-image,
  .single-image img {
    height: 400px;
  }

  .author-card-avatar img {
    margin-top: -20%;
  }

  .order-box {
    width: 50px;
    height: 50px;
    font-size: 15px;
  }

  .order-detail p {
    font-size: 12px;
  }

  .order-detail {
    padding: 20px 0;
  }

  .order-detail h5 {
    font-size: 13px;
  }

  .subtotal-order button {
    margin-bottom: 30px;
  }

  .single-product {
    margin-top: 20px;
  }

  .login-center {
    padding: 20px 0;
  }

  .container {
    /* overflow: scroll; */
    /* height: calc(100vh - 50px); */
  }
}

@media (max-width: 768px) {
  .pc-header {
    display: none !important;
  }

  .mobile-header {
    display: block;
  }

  .cart-mobile-icon i {
    font-size: 13px;
    margin-left: 10px;
  }

  .btn-group button {
    font-size: 12px;
  }

  .mobile-header img {
    width: 100%;
  }

  .input-group {
    width: 100%;
    margin-top: 5px;
  }

  .search-button {
    width: 30%;
  }

  .subscribe-head .form-section input[type="email"] {
    height: 45px;
    width: 70%;
  }

  .subscribe-head .form-section input[type="submit"] {
    height: 47px;
    padding: 0 58px;
  }

  .subscribe-head h2 {
    font-size: 25px;
  }

  .contact-Box {
    margin: 30px 0;
  }

  .contactInfo {
    padding: 20px 0;
  }

  .cart-buttons {
    margin: 40px 0;
  }

  .cart-buttons a button,
  .cart-buttons .d-flex button {
    width: 100%;
  }

  .total .sub,
  .total .total-price {
    font-size: 16px;
  }

  .single-image {
    margin-bottom: 50px;
    margin-right: 0;
  }

  .product-name {
    font-size: 23px;
  }

  .author-card-avatar {
    display: none;
  }

  .order-detail {
    margin-top: 20px;
  }

  .login-center {
    padding: 20px 0;
  }

  .head-contact-root {
    display: none;
  }

  .Login-Register {
    width: 44% !important;
  }

  .section-scroll {
    height: calc(100vh - 130px);
  }
}

@media (max-width: 575.98px) {
  .subscribe-head .form-section input[type="email"] {
    width: 100%;
  }

  .subscribe-head .form-section input[type="submit"] {
    width: 100%;
    padding: 0 0;
    margin-top: 20px;
    margin-left: 0px;
  }

  .subscribe-head h2 {
    font-size: 25px;
  }

  .subscribe-section {
    padding: 70px 0 70px;
  }

  .card-name {
    width: 8%;
    margin: 0 7px;
  }

  .login-center {
    padding: 20px 0;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .center h5 {
    font-size: 17px;
    margin-top: 10px;
  }

  .center p {
    font-size: 17px;
  }

  .head-contact-root {
    display: none;
  }

  .input-group {
    height: 38px;
    margin-top: unset;
  }
}

@media (max-width: 400px) {
  .subscribe-head h2 {
    font-size: 20px;
  }

  .single-image,
  .single-image img {
    height: 200px;
  }

  .subscribe-section {
    padding: 30px 0 30px;
  }

  .head-contact-root {
    display: none;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

/* body {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  background: #ede7f6;
} */

footer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 100px;
  background: #111;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer .container {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  flex-direction: row;
}

footer .container .sec {
  margin-right: 30px;
}

footer .container .sec.aboutus {
  width: 40%;
}

footer .container h2 {
  position: relative;
  color: #fff;
  font-weight: 500;
  margin-bottom: 16px;
}

footer .container h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background: #de6b1a;
}

footer p {
  color: #999;
}

.sci {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.sci li {
  list-style: none;
}

.sci li a {
  /* display: inline-block; */
  width: 40px;
  height: 40px;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-decoration: none;
  border-radius: 50%;
}

.sci li a:hover {
  background: #892a10;
}

.sci li a .fa-at {
  color: #fff;
  font-size: 20px;
}

.sci li a .fa-at:hover {
  color: #1cb803;
  font-size: 20px;
}

.sci li a .fa-facebook {
  color: #fff;
  font-size: 20px;
}

.sci li a .fa-facebook:hover {
  color: #1cb803;
  font-size: 20px;
}

.quickLinks {
  position: relative;
  width: 25%;
}

.quickLinks ul li a {
  color: #999;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}

.quickLinks ul li a:hover {
  color: #fff;
}

.quickLinks ul {
  list-style: none;
  padding-left: 0px !important;
}

.offer {
  position: relative;
  width: 25%;
}

.offer ul li a {
  color: #999;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}

.offer ul li a:hover {
  color: #fff;
}

.offer ul {
  list-style: none;
  padding-left: 0px !important;
}

.contact {
  width: calc(35% -60px);
  margin-right: 0 !important;
}

.contact ul {
  list-style: none;
  padding-left: 0px !important;
}

.contact .info li {
  display: flex;
  margin-bottom: 16px;
}

.contact .info li span:nth-child(1) {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.contact .info li span {
  color: #999;
}

.contact .info li a {
  color: #999;
  text-decoration: none;
}

.contact .info li a:hover {
  color: #fff;
}

.copyrightText {
  width: 100%;
  background: #181818;
  padding: 8px 100px;
  text-align: center;
  color: #999;
}

.copyrightText a {
  color: #999;
  text-decoration: none;
  color: #1cb803;
}

.copyrightText img {
  width: 120px;
  height: 35px;
  margin-right: 8px;
  cursor: pointer;
}

.copyrightText p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.author {
  margin-left: 6px;
}

@media (min-width) {
  footer {
    padding: 0;
  }

  footer .container {
    flex-direction: column;
  }

  footer .container .sec {
    margin-right: 0 !important;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 996px) {
  footer {
    padding: 8px;
  }

  footer .container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 910px) {
  footer .container .sec.aboutus {
    width: 40%;
  }

  footer .container .sec.quickLinks {
    width: 40%;
  }

  footer .container .sec.offer {
    width: 40%;
  }

  footer .container .sec.contact {
    width: 40%;
  }
}

@media only screen and (max-width: 642px) {
  .offer {
    width: 41%;
  }
}

@media only screen and (max-width: 600px) {
  .copyrightText p {
    flex-wrap: wrap;
    flex-direction: column;
  }

  footer .container .sec.aboutus {
    width: 100%;
  }

  footer .container .sec.quickLinks {
    width: 100%;
  }

  footer .container .sec.offer {
    width: 100%;
  }

  footer .container .sec.contact {
    width: 100%;
  }

  .sci {
    justify-content: center;
  }
}

@media only screen and (max-width: 594px) {
  .copyrightText p {
    flex-wrap: wrap;
  }

  .copyrightText img {
    width: 100%;
    height: unset;
  }
}

@media only screen and (max-width: 594px) {
  .footText {
    width: 220px;
  }
}

@media only screen and (max-width: 540px) {
  .footText {
    width: 500px;
  }
}

@media (max-width: 414px) {
  .footText {
    max-width: 100% !important;
  }

  footer .container .sec {
    margin-right: unset;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.app {
  font-family: "Roboto";
  height: 50px;
  padding: 8px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.dropdown-container {
  width: 250px;
  position: relative;
}

.dropdown-container input {
  width: 100%;
  padding: 0;
  border-width: 1px;
  height: 36px;
  padding-left: 10px;
  box-sizing: border-box;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-arrow-container {
  position: absolute;
  right: 10px;
  top: 20%;
  -webkit-transform: rotateY(-50%);
  transform: rotateY(-50%);
  margin-top: -2px;
  cursor: pointer;
}

.input-arrow {
  border: solid #272727;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(45deg);
  -webkit-transform: rotate(45deg);
}

.option {
  height: 36px;
  border-bottom: 1px solid #272727;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  background-color: #fff;
}

.option:hover {
  background-color: royalblue;
}

.dropdown {
  border: 1px solid #272727;
  border-bottom: none;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  display: none;
}

.dropdown.visible {
  display: block;
}

.input-clear-container {
  position: absolute;
  right: 30px;
  top: 20%;
  -webkit-transform: rotateY(-50%);
  transform: rotateY(-50%);
  cursor: pointer;
}

header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 2rem;
  background-color: #fffcf7;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

nav a {
  margin: 0 2rem;
  color: #fff;
  text-decoration: none;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 1084px){
  header .nav-btn{
    visibility: visible;
    opacity: 1;
  }

  header nav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: #892a10;
    transition: 1s;
    -webkit-transform: rotateY(-100vh);
    transform: rotateY(-100vh);
    z-index: 99;
  }

  header .responsive_nav{
    -webkit-transform: none;
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --heights: 60vh;
  --widths: 100%
}

.slider-container {
  height: 60vh;
  height: var(--heights);
  width: 100%;
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 60vh;
  height: var(--heights);
  width: 100%;
  width: var(--widths);
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 40%;
  z-index: 10;
}

.slide-text {
  top: 65%;
  font-size: 2rem;
}

.prev,
.next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 40px;
  font-weight: 600;
  border-radius: 0px 5px 5px 0px;
}

.prev:hover,
.next:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.active-dot,
.dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* purgecss end ignore */
